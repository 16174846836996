import jsXLS from "js-export-excel";

class XLSjs {

    Reporte3(data,currency,reserva,totalbenf1,totalbenf2) {
        var option = {};
        var pagar1 = 0;
        var pagar2 = 0;
        option.fileName = "Report Claims";
        var body = [];
        for (var i = 0; i < data.length; i++) {
            pagar1 = 0;
            pagar2 = 0;
            if(data[i].benef_pagado){
                pagar2 = data[i].benef_total;
            } else {
                pagar1 = data[i].benef_total;
            }
			body.push({
                C1: i+1,
                C2: this.convfech(data[i].fecha),
                C3: data[i].nombre,
                C4: data[i].poliz,
                C5: data[i].codigo,
                C6: data[i].veh_placa,
                C7: data[i].benef_nombre,
                C8: data[i].estado_name,
                C9: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(data[i].estimacion).toFixed(2)),
                C10: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(pagar1).toFixed(2)),
                C11: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(pagar2).toFixed(2)),
                C12: data[i].observacion,
            });
        }
        body.push({
            C1: null,
            C2: null,
            C3: null,
            C4: null,
            C5: null,
            C6: null,
            C7: null,
            C8: 'TOTAL',
            C9: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(reserva).toFixed(2)),
            C10: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(totalbenf1).toFixed(2)),
            C11: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(totalbenf2).toFixed(2)),
            C12: null,
        });

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12"],
            sheetHeader: ['Nro', 'Incident date', 'Policyholder', 'Policy #', 'Claim #',
                 'Lic. Plate', 'Counterparty', 'Status', 'Reserve Amount','To Pay','Payment',
                 'Comments: Status changes.'],
            columnWidths: [2, 5, 10, 10, 10, 5, 10, 5, 8, 8, 8, 15],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte1(data) {
        var option = {};
        option.fileName = "Report Policies";
        var body = [];
        var status = '';
        for (var i = 0; i < data.length; i++) {
            if(data[i].estado==4){
                status = 'Payment Verification';
            } else if(data[i].vend<0){
                status = 'Expired';
            } else if(data[i].estado==6){
                status = 'Cancelled';
            } else if(data[i].pendiente>0){
                status = 'Expired Debt';
            } else if(data[i].proceso>0){
                status = 'Overdue Payment';
            } else if(data[i].estado==7){
                status = 'Renewal Process';
            } else {
                status = 'Active';
            }
			body.push({
                C1: i+1,
                C2: status,
                C3: data[i].codigo,
                C4: data[i].cliente,
                C5: data[i].nombre,
                C6: data[i].duracion_name,
                C7: data[i].activacion,
                C8: data[i].vence,
                C9: data[i].cuotas,
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9"],
            sheetHeader: ['Nro', 'Status', 'Policy #', 'Customer #', 'Full Name', 'Duration', 'Activation', 'Expiration', 'Installments'],
            columnWidths: [2, 10, 10, 5, 10, 5, 5, 5, 5],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte2(data,currency) {
        var option = {};
        option.fileName = "Report Quotes";
        var body = [];
        for (var i = 0; i < data.length; i++) {
			body.push({
                C1: i+1,
                C2: data[i].estado_name,
                C3: data[i].id,
                C4: data[i].evaluacion.tipopoliza_name,
                C5: data[i].duracion_name,
                C6: data[i].cuotas+' Quotas',
                C7: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(data[i].evaluacion.montofinal/data[i].evaluacion.cuotas).toFixed(2)),
                C8: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(data[i].evaluacion.montofinal).toFixed(2)),
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8"],
            sheetHeader: ['Nro', 'Status', 'N°', 'Type', 'Duration', 'Installment', 'Installment Amount', 'Total Amount'],
            columnWidths: [2, 10, 5, 10, 5, 5, 5, 7, 7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte4(data) {
        var option = {};
        option.fileName = "Report Complaints";
        var body = [];
        for (var i = 0; i < data.length; i++) {
			body.push({
                C1: data[i].id,
                C2: data[i].codigo,
                C3: data[i].cliente,
                C4: data[i].nombrepoliz,
                C5: this.convfech(data[i].fecha),
                C6: data[i].caso_name,
            });
        }
        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6"],
            sheetHeader: ['Nro', 'Policy #', 'Customer #', 'Full Name', 'Date', 'Department'],
            columnWidths: [2, 10, 7, 10, 7, 15],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte5(data,opciones,currency) {
        var option = {};
        option.fileName = "Report Payments";
        var body = [];
        var estatus = '';
        for (var i = 0; i < data.length; i++) {
            if(data[i].pagada){
                estatus = opciones[2].name;
            } else {
                estatus = opciones[1].name;
            }
			body.push({
                C1: estatus,
                C2: data[i].fechad,
                C3: data[i].id,
                C4: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(data[i].monto).toFixed(2)),
                C5: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(data[i].recibido).toFixed(2)),
                C6: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(data[i].pendiente).toFixed(2)),
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6"],
            sheetHeader: ['Status', 'Date', 'Nro', 'Amount', 'Payments', 'Debts'],
            columnWidths: [10, 7, 5, 7, 7, 7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte6(data,currency) {
        var option = {};
        option.fileName = "Report Installments";
        var body = [];
        for (var i = 0; i < data.length; i++) {
			body.push({
                C1: data[i].cliente,
                C2: data[i].nombre,
                C3: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(data[i].monto).toFixed(2)),
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3"],
            sheetHeader: ['Customer #', 'Full Name', 'Debts'],
            columnWidths: [10, 20, 10],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Reporte7(data,currency) {
        var option = {};
        option.fileName = "Report Accounting";
        var body = [];
        for (var i = 0; i < data.length; i++) {
			body.push({
                C1: data[i].activacion,
                C2: data[i].codigo,
                C3: data[i].cliente,
                C4: data[i].created.nombre,
                C5: data[i].created.origen,
                C6: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(data[i].monto).toFixed(2)),
                C7: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(data[i].pagos).toFixed(2)),
                C8: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(data[i].monto-data[i].pagos).toFixed(2)),
                C9: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(data[i].efectivo).toFixed(2)),
                C10: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(data[i].iss).toFixed(2)),
                C11: new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(data[i].broker).toFixed(2)),
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11"],
            sheetHeader: ['Date', 'Policy #', 'Customer #', 'User', 'Office', 'Total Premium', 
                'Paind', 'Pending Payment', 'Cash', 'Sabee', 'Broker'],
            columnWidths: [5, 10, 5, 15, 15, 7, 7, 7, 7, 7, 7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }



    convfech(fecha){
        return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
    }



}


export default XLSjs;